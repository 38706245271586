export default {
  not_found_title: 'Упс... 404!',
  not_found_text: 'Запрашиваемая вами страница не найдена.',
  main_page: 'Вернуться в Хаб',
  error_title: 'Ошибка сервера',
  forbidden_title: 'Нет доступа',
  forbidden_text: 'Пройдите регистрацию чтобы начать пользоваться платформой:',
  forbidden_text_1: 'У вас нет доступа к этому событию',
  forbidden_text_2: 'Чтобы получить доступ к платформе, вам нужен билет.',
  forbidden_text_3: 'Авторизуйтесь под другим аккаунтом или получите билет.',
  log_in: 'Войти',
  room_full_title: 'Комната заполнена',
  room_full_text: 'Попробуйте зайти позже.',
  change_account: 'Войти с другого аккаунта',
  link_error_title: 'Ссылка недействительна',
  link_error_text: 'Срок действия волшебной ссылки истек',
  upgrade_required_title: 'Необходимо сменить тип билета',
  upgrade_required_text_1: 'At this stage there are no more free passes available for service providers.',
  upgrade_required_text_2: 'If you would like to join the :name, please purchase a Business Pass',
  upgrade_required_text_2_1: 'Чтобы активировать этот и другой платный функционал, пожалуйста, купите билет.',
  upgrade_required_text_2_2: 'If you wold like to join Stream please purchase a Business Pass!',
  upgrade_required_text_3: 'После покупки необходимо обновить страницу!',
  upgrade_required_button: 'Оплатить',
  no_network_title: 'Нет соединения',
  no_network_text: 'Проверьте соединение с сетью и обновите страницу',
  no_network_button: 'Обновить страницу',
  vote_failed: 'Голос не засчитан',
  no_company_title: 'Необходимо указать компанию',
  no_company_body: 'Чтобы добавить игру, пожалуйста, укажите компанию в настройках своего профиля.',
  no_company_tip: 'Не забудьте обновить страницу после указания компании.',
  forbidden_route_title_default: 'Данный раздел недоступен',
  forbidden_route_title_ticket: 'Данный раздел недоступен для вашего типа билета',
  forbidden_route_ticket_upgrade_text: 'Вы можете улучшить свой билет',
  forbidden_route_ticket_upgrade_button: 'Проапгрейдить билет',
  unknown_error_title: 'Неизвестная ошибка',
  unknown_error_text: 'Разработчики будут уведомлены об этой проблеме.',
}
