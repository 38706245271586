import { Expose, Type, Transform, Exclude } from 'class-transformer'
import dayjs from 'dayjs'
import { pluralize } from '/~/plugins/utils'
import { User } from './user'
import { LectureCategory } from './lecture-category'
import { BaseModel } from './baseModel'
import { removeTags } from '/-/plugins/format'
import { marked } from '/~/plugins/marked'
import { Media } from './media'
import { VideoSource, getVideoSrc, getVideoIdFromUrl } from '/-/plugins/helpers/videoHelper'
import { OptionInterface } from '/-/components/controls/setup'
import { Event } from './event'
import { useWNMeet } from '/~/state/wnmeet'
import { useError } from '/~/plugins/error'
import { useApplication } from '/~/state/application'

export type LectureIdType = number

export const LectureVideoSourceOptions: OptionInterface[] = [
  { value: VideoSource.Youtube, label: 'Youtube' },
  { value: VideoSource.GCore, label: 'G-Core' },
  { value: VideoSource.Google, label: 'Google Drive' },
  { value: VideoSource.WNMeet, label: 'WN Meet' }
]

export enum LectureVideoTypes {
  OPEN = 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OPEN_IF_AGREE = 2,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OPEN_IF_TICKET = 3,
  CLOSED = 4
}

export enum LectureVisibility {
  'For everyone' = 1,
  'For auth users',
  'For users with ticket'
}

export const LECTURE_DURATION_TIME_UNIT = 'minute'

export class LectureTag extends BaseModel {
  id!: number
  name!: string
}

export class Lecture extends BaseModel {
  id!: LectureIdType
  title!: string
  description!: string

  @Transform(({ value }: any) => value === '' ? null : value, { toPlainOnly: true })
  date!: string

  duration!: number
  preview!: Media | null

  language!: string

  @Exclude({ toPlainOnly: true })
  event!: Event

  get descriptionMarked(): string {
    return marked(removeTags(this.description))
      .replace(/<a href=/g, '<a target="_blank" href=')
  }

  @Expose({ name: 'video_id' })
  videoId?: string

  @Expose({ name: 'video_url' })
  videoUrl?: string

  @Expose({ name: 'video_source' })
  videoSource!: VideoSource

  @Type(() => User)
  @Transform(({ obj }: any) => obj.speakerIds, { toPlainOnly: true })
  speakers: User[] = []

  @Expose({ name: 'speakerIds' })
  @Exclude({ toPlainOnly: true })
  @Transform(({ obj }: any) => obj.speakers.map((speaker: any) => speaker.id), { toClassOnly: true })
  speakerIds!: number[]

  @Type(() => LectureCategory)
  @Exclude({ toPlainOnly: true })
  category?: LectureCategory

  @Type(() => LectureTag)
  tags!: LectureTag[]

  @Expose({ name: 'visibility_type' })
  visibility!: LectureVisibility

  categories: number[] = []

  tempVideoSrc = ''

  get durationCaption(): string {
    return this.duration ? pluralize(this.duration, LECTURE_DURATION_TIME_UNIT) : ''
  }

  get formattedDate(): string {
    return this.date ? dayjs(this.date).format('MMM D, YYYY') : ''
  }

  get previewUrl(): string | undefined {
    return this.preview?.url
  }

  get videoIdExtracted(): string {
    return this.videoId || getVideoIdFromUrl(this.videoSource, this.videoUrl)
  }

  get videoSrc() {
    return getVideoSrc({
      videoSource: this.videoSource,
      videoId: this.videoId,
      videoUrl: this.videoUrl
    })
  }

  get isWNMeetVideo() {
    return this.videoSource === VideoSource.WNMeet
  }

  get isOpenInBrowser() {
    const { isNative, isIOS } = useApplication()

    return this?.videoSource === VideoSource.Google && isNative && isIOS
  }

  get isGoogleDriveSource() {
    return this?.videoSource === VideoSource.Google
  }

  async setTempVideoSrc() {
    if (this.videoUrl && this.isWNMeetVideo) {
      this.tempVideoSrc = ''
      const wnMeetStore = useWNMeet()
      const { processError } = useError()

      try {
        this.tempVideoSrc = await wnMeetStore.getVideoLink(this.event.id, this.videoUrl)
      } catch (error: any) {
        processError(error, false)
      }
    }
  }
}

export class LectureStream extends BaseModel {
  status!: LectureVideoTypes
  source!: {
    src: string
    type: string
  }
}
