<template>
  <div class="absolute inset-0"
       @click="openInBrowser" />
</template>
<script setup lang="ts">
import { Browser } from '@capacitor/browser'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
})

function openInBrowser() {
  Browser.open({ url: props.src?.replace('/preview', '/view') })
}
</script>
