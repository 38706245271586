export default {
  create: 'Создание встречи',
  create_online: 'Создание онлайн встречи',
  is_online: 'Онлайн встреча',
  agenda: 'Тема встречи',
  date: 'Дата',
  time_slot: 'Время',
  time: 'Время',
  request: 'Запросить',
  participants: 'Участники',
  cancel: 'Отмена',
  meetings: 'Встречи',
  meeting: 'Встреча',
  join_meeting: 'Видеозвонок',
  calendar: 'Календарь',
  select_timeslot_prompt: 'Выберите время',
  today: 'Сегодня',
  invites: 'Приглашения',
  upcoming: 'Предстоящие',
  location: 'Локация',
  online: 'Онлайн',
  onsite: 'On-site',
  invited: 'Приглашен',
  accepted: 'Принято',
  rejected: 'Отклонено',
  canceled: 'Отменено',
  cancel_verb: 'Отменить',
  reschedule: 'Перенести',
  send_message: 'Сообщение',
  reject: 'Отклонить',
  decline: 'Отклонить',
  accept: 'Принять',
  reason: 'Причина',
  cancel_meeting: 'Отменить встречу',
  save: 'Сохранить',
  day: 'День',
  days: 'Дней',
  week: 'Неделя',
  time_slot_locked_title: 'Вы заблокировали этот слот',
  time_slot_locked_message: `Слот заблокирован.
  Разблокируйте его в календаре или перенесите встречу на другой слот.`,
  time_slot_busy_message: `Слот занят.
  Перенесите встречу на другой слот.`,
  no_meetings: 'Нет предстоящих встреч',
  status_outcoming: 'исходящее приглашение',
  status_declined: 'отменено',
  status_accepted: 'принято',
  status_incoming: 'входящее приглашение',
  status_waiting: 'ожидают',
  status_blocked: 'заблокированы',
  unlock: 'разблокировать',
  lock: 'заблокировать',
  location_hint_text: 'Чтобы сориентироваться в экспозоне, ознакомьтесь с',
  location_hint_link: 'планом этажа',
  my_calendar: 'Мой календарь на',
  month: 'Месяц',
  calendar_hint_meetings: 'Есть встречи в этот день',
  next_day: 'Следующий день',
  prev_day: 'Предыдущий день',
  invites_modal_title: 'Запросы',
  invites_modal_subtitle: 'У вас есть неподтвердженные встпечи. Пожалуйста, подтвердите.',
  view_all_requests: 'Просмотреть все запросы',
  invites_page_title: 'Запросы на встречи',
  invites_page_subtitle: 'У вас есть неподтвержденные встречи. Пожалуйста, подтвердите.',
  go_to_calendar: 'Календарь',
  back_to_calendar: 'Назад в календарь',
  back_to_invites: 'Назад к приглашениям',
  no_invites: 'У вас нет запросов на встречи.',
  create_meeting: 'Назначить встречу',
  show_more: 'Показать еще ',
  requests_warning_title: 'У вас есть запросы на встречи',
  requests_warning_subtitle: 'ожидают вашего решения.',
  view: 'Просмотреть',
  meeting_view_status_waiting_user_response: 'Статус: ожидает вашего ответа',
  meeting_view_status_waiting_other_response: 'Статус: ожидает :name\'s ответа',
  meeting_view_status_accepted: 'Статус: встреча подтверждена',
  meeting_view_status_declined_by_user: 'Статус: вы отменили встречу',
  meeting_view_status_declined_by_other: 'Статус: встреча была отменена',
  suggets_changes: 'Предложить другое время или локацию',
  organizer: 'Организатор',
  new_meeting: 'Запрос на новую встречу',
  new_online_meeting: 'Запрос на онлайн встречу',
  choose_participants: 'Выберите пользователя, кликнув по его имени. :count участников на этом ивенте.',
  sent_notification: 'Отправить напоминание за 10 минут до встречи',
  select_notification_time: 'Выбрать время напоминания',
  event_is_over: 'Извините, этот ивент завершен.',
  cannot_create_meetings: 'Вы больше не можете назначать встречи.',
  no_avaliable_timeslots: 'Извините, нет доступных слотов для встреч в этот день',
  choose_another_day: 'Пожалуйста, выберите другой день',
  timeslot_busy: 'Этот слот заблокирован или содержит подтвержденную встречу',
  online_only: 'только online',
  success_screen_title: 'Успешно! ',
  success_screen_subtitle: 'Запрос на встречу был направлен пользователю',
  success_screen_edit_subtitle: 'Запрос на изменение встречи бы напрален пользователю',
  add_to_google_calendar: 'Добавить в Google Calendar',
  add_to_calendar: 'Добавить в Календарь',
  meeting_point: 'Meeting point',
  open_meeting: 'Подробнее о встрече',
  removed_location: 'Локация была удалена, пожалуйста, свяжитесь с организатором',
  decline_form_description: 'Пожалуйста, расскажите нам о причине отмены встречи. Эта информация поможет нам улучшить сервис и сделать встречи более полезными и релевантными.',
  decline_reason_1: 'Нерелевантный контакт',
  decline_reason_2: 'Мои планы изменились',
  decline_reason_3: 'Это СПАМ',
  decline_reason_custom: 'Другая причина',
  send: 'Отправить',
  reschedule_meeting: 'Переназначить встречу',
  meeting_is_past: 'Эта встреча уже завершена',
  participant: 'Участник',
  participant_suggested_to_change: ':участник предложил изменения во встрече',
  change_details: ':type с :old по o :new',
  meeting_from_another_event: 'Встреча назначена на дрегом ивенте',
  initial_timedate_is_invalid: 'Вы выбрали недоступную дату или время. Ближайшие доспупные опции выбраны автоматически.',
  change_meeting: 'Изменить встречу',
  view_meeting: 'Просмотреть встречу',
  decline_meeting_question: 'Вы действительно хотите отменить встречу? ',
  online_meeting: 'Online встреча',
  onsite_meeting: 'On-site встреча',
  filter_approved: 'Подтвержденные',
  filter_rejected: 'Отмененные',
  filter_waiting: 'Ожидают',
  does_not_participate_onsite: 'не присутствует на ивенте, только онлайн',
  no_meetings_with_status: 'Нет встреч с этим статусом',
  cancel_confirm_title: 'Вы действительно хотите закрыть форму?',
  cancel_confirm_text: 'В этом случае, данные не будут сохранены.',
  meetings_over: 'Извините, больше не возможно создать встречу с этиим параметрами.'
}
